@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NotepaperLight";
  src: url("./fonts/notepaper/Notepaper-Airplanes-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Notepaper";
  src: url("./fonts/notepaper/Notepaper-Airplanes.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  border: 10px solid hsl(0, 0%, 95%); /* white */
  border-top: 10px solid hsl(42, 17%, 85%);
  border-bottom: 10px solid hsl(42, 17%, 85%);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input {
  outline: none;
}

@keyframes spin-plus {
  0% {
    transform: rotate(0deg);
    opacity: 100;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}

@keyframes spin-plus-reverse {
  0% {
    transform: rotate(360deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 100;
  }
}

.plus-animation {
  animation: spin-plus 0.2s alternate ease-out forwards;
}
.plus-animation-reverse {
  animation: spin-plus-reverse 0.2s alternate ease-out forwards;
}

.accordion-item h2,
.accordion-item h3 {
  font-weight: 700 !important;
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
  font-size: 14px !important;
}

.accordion-item p {
  font-size: 14px !important;
  padding-bottom: 0.5rem !important;
}

.vimeo-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
}

.vimeo-video-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vimeo-video-wrapper-feature {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
}

.vimeo-video-wrapper-feature iframe {
  width: 100vw;
  height: 47.25vw;
  min-height: 54vh;
  min-width: 97.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vimeo-video-wrapper-object iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vimeo-video-wrapper-full iframe {
  height: 56.25vw;
  left: 50%;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 100vw;
}